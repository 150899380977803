var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticStyle:{"margin-top":"20px","margin-right":"10px","margin-left":"10px","margin-bottom":"30px","padding":"10px 30px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"margin-top":"10px","margin-bottom":"20px"},attrs:{"cols":"12"}},[_c('div',{class:("d-flex justify-start " + (_vm.wWidth < 769 ? 'flex-column' : 'flex-row')),staticStyle:{"border-radius":"10px 10px 0 0"}},[_c('v-toolbar-title',[_vm._v("List Project")]),_c('v-divider',{staticClass:"mx-6",attrs:{"inset":"","vertical":""}}),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('router-link',{attrs:{"to":"/e-catalogue/project/submission"}},[_c('v-btn',{staticClass:"indigo--text",staticStyle:{"font-size":"12px","margin-right":"40px"},attrs:{"outlined":"","small":"","elevation":"1","color":"indigo"}},[_vm._v(" Buat Baru ")])],1)],1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('div',{staticStyle:{"margin-top":"5px","margin-bottom":"15px","margin-right":"15px","width":"120px"}},[_c('v-select',{staticStyle:{"position":"relative","top":"15px","margin":"0 15px 0 5px","font-size":"12px"},attrs:{"disabled":_vm.selectedData.length === 0,"label":"Action","items":_vm.itemsAction,"item-text":"name","item-value":"id","outlined":"","return-id":"","dense":""},on:{"change":_vm.actionSelect},model:{value:(_vm.actionValue),callback:function ($$v) {_vm.actionValue=$$v},expression:"actionValue"}})],1),_c('div',{staticStyle:{"margin-top":"5px","margin-bottom":"15px","margin-right":"10px"}},[(
                _vm.getUserProfile.level.find(function (ref) {
                          var id = ref.id;

                          return id === '41';
}) !==
                undefined
              )?_c('v-autocomplete',{staticStyle:{"margin":"0","height":"42px"},attrs:{"items":_vm.dropdownCompany,"label":"- Perusahaan -","item-text":"name","item-value":"id","return-id":"","outlined":"","dense":"","clearable":""},on:{"change":_vm.changeCompany},model:{value:(_vm.paramAPI.company_id),callback:function ($$v) {_vm.$set(_vm.paramAPI, "company_id", $$v)},expression:"paramAPI.company_id"}}):_c('v-autocomplete',{staticStyle:{"margin":"0","height":"42px"},attrs:{"items":_vm.dropdownCompany,"label":"- Perusahaan -","item-text":"name","item-value":"id","return-id":"","outlined":"","dense":"","disabled":_vm.isDisableCompanyDropdown,"clearable":""},on:{"change":_vm.changeCompany},model:{value:(_vm.paramAPI.company_id),callback:function ($$v) {_vm.$set(_vm.paramAPI, "company_id", $$v)},expression:"paramAPI.company_id"}})],1),_c('div',{staticStyle:{"margin-left":"auto","height":"42px","width":"320px","margin-top":"5px","margin-bottom":"15px","margin-right":"10px"}},[_c('v-text-field',{attrs:{"label":"Cari disini","type":"cari","outlined":"","dense":"","append-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEnter($event)}},model:{value:(_vm.paramAPI.keyword),callback:function ($$v) {_vm.$set(_vm.paramAPI, "keyword", $$v)},expression:"paramAPI.keyword"}})],1)])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.result,"footer-props":{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
          },"server-items-length":_vm.totalData,"page":_vm.paramAPI.page,"items-per-page":_vm.paramAPI.itemsPerPage,"item-key":"id","show-select":""},on:{"click:row":_vm.rowClick,"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemPerPage},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.status(item.status))+" ")])]}}],null,true),model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}})],1)],1)],1),_c('div',{staticStyle:{"position":"fixed","bottom":"10px","right":"0"}},[_c('Scanner')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }